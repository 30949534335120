
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: object | any
}
