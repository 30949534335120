
























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import LinkSelect from '@/components/link-select/index.vue'
import Draggable from 'vuedraggable'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        MaterialSelect,
        LinkSelect,
        Draggable
    }
})
export default class Attribute extends Vue {
    @Prop() content!: any
    @Prop() styles!: any
    /** S data **/
    $refs!: { materialSelect: any }
    /** E data **/

    /** S computed **/

    /** E computed **/

    /** S methods **/
    onAdd() {
        if (this.content.data.length > 5) {
            return this.$message.warning('最多五个导航')
        }
        this.content.data.push({
            icon: '',
            select_icon: '',
            name: '导航',
            link: {}
        })
    }
    onDelete(index: number) {
        const { data } = this.content
        if (index == 0) {
            return this.$message.warning('首页导航不能删除')
        }
        if (data.length <= 2) {
            return this.$message.warning('最少两个导航')
        }
        this.content.data.splice(index, 1)
    }
    onMove(e: any) {
        if (e.relatedContext.index == 0) {
            return false
        }
        return true
    }
    /** E methods **/
}
