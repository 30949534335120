



























import { Component, Prop, Vue } from 'vue-property-decorator'
import tabbar from '@/components/decorate/widgets/tabbar'
import { apiThemeConfig, apiThemeConfigSet } from '@/api/shop'
@Component({
    components: {
        WTabbar: tabbar.widget,
        ATabbar: tabbar.attribute
    }
})
export default class ShopTabbar extends Vue {
    tabbar = {
        content: {
            style: '1',
            data: []
        },
        styles: {
            bg_color: '#FFFFFF',
            color: '#666666',
            text_select_color: '#FF2C3C'
        }
    }

    /** S methods **/
    getThemeConfig() {
        apiThemeConfig({ type: 2 }).then(res => {
            if (res.tabbar) {
                this.tabbar = res.tabbar
            }
        })
    }

    handleSave() {
        apiThemeConfigSet({
            type: 2,
            content: {
                tabbar: this.tabbar
            }
        })
    }
    /** E methods **/

    /** S life cycle **/
    created() {
        this.getThemeConfig()
    }
    /** E life cycle **/
}
